:root {
	--btn0: hsl(32, 100%, 60%);
	--btn1: hsl(64, 100%, 60%);
	--btn2: hsl(96, 100%, 60%);
	--btn3: hsl(128, 100%, 60%);
	--btn4: hsl(160, 100%, 60%);
	--btn5: hsl(192, 100%, 60%);
	--btn6: hsl(224, 100%, 60%);
	--btn7: hsl(256, 100%, 60%);
	--btn8: hsl(288, 100%, 60%);
	--btn9: hsl(320, 100%, 60%);

	--abortBackground: hsla(0, 100%, 40%, 0.7);
	--paymentBacground: hsla(0, 0%, 50%, 0.7);
	/* --paymentBacground: hsla(0, 0%, 0%, 0.75); */

	--confirm: hsl(90, 100%, 40%);
	--abort: hsl(0, 100%, 40%);
	--light: white;
	--lightgray: gainsboro;
	--darkgray: #5d5d5d;
	--dark: black;
	--error: hsl(0, 100%, 40%);

	--ButtonStdBgColor: var(--confirm);
	--ButtonStdTxtColor: var(--dark);
	--emptyFild: var(--darkgray);

	--backdrop: var(--paymentBacground);
}

* {
	margin: 0;
	box-sizing: border-box;
	user-select: none;
}
