.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: gainsboro;
}

.closer {
	position: fixed;
	top: 0;
	right: 1%;
	cursor: pointer;
}

.productContainer {
	font-size: 22px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.productRow {
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.productName {
	width: 50%;
}

.productPrice {
	width: 15%;
}

.productAdd {
	margin-top: 20px;
	cursor: pointer;
}

.edit {
	width: fit-content;
	padding: 1% 0;
	cursor: pointer;
}

@media (max-width: 1080px) {
	.productRow {
		width: 90%;
	}
}
