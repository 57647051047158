.background {
	position: fixed;
	top: 0%;
	left: 0%;
	height: 100%;
	width: 100%;
	background-color: hsla(0, 0%, 50%, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: 1;
}

.wrapper {
	position: fixed;
	left: 25%;
	top: 25%;
	width: 50%;
	height: 50%;
	z-index: 50;
	border: 2px solid black;
	box-shadow: black 0px 0px 25px;
	border-radius: 10px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	text-align: center;
}
