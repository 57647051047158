.button {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 120px;
	min-width: 200px;
	width: 10%;
	height: 20%;
	max-height: 200px;
	margin: 2%;
	border: 1px solid var(--dark);
	box-shadow: var(--darkgray) 5px 5px 10px;
	text-shadow: var(--light) 0px 0px 7px;
}

@media (max-width: 1080px) {
	.button {
		min-width: 150px;
		min-height: 80px;
		font-size: 0.7rem;
	}
	.button h2 {
		font-size: 1.6rem;
	}
}
