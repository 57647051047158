.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 75%;
	height: 100%;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

@media (max-width: 1080px) {
	.container {
		width: 100%;
	}
}
