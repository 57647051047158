.container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	width: 80%;
}

.buttons {
	background-color: lightgrey;
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	text-align: center;
	border: 1px solid black;
	border-radius: 10px;
	padding-top: 3%;
	padding-bottom: 3%;
}

.keyNumb {
	width: 30%;
	height: 70px;
	max-width: 125px;
	margin: 1%;
	background-color: grey;
	font-size: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
