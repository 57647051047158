.container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 94%;
	max-height: 95%;
	overflow: auto;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	overflow: auto;
}
