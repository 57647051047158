.ldsDualRing {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: 164px;
	height: 164px;
}
.ldsDualRing2 {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: 184px;
	height: 184px;
}

.ldsDualRing:after {
	content: ' ';
	display: block;
	width: 86px;
	height: 86px;
	margin: 1px;
	border-radius: 50%;
	border: 12px solid black;
	border-color: var(--light) transparent var(--light) transparent;
	/* border-color: var(--confirm) transparent var(--confirm) transparent; */
	animation: ldsDualRing 1.2s linear infinite;
}
.ldsDualRing2:after {
	content: ' ';
	display: block;
	width: 176px;
	height: 176px;
	margin: 1px;
	border-radius: 50%;
	border: 12px solid black;
	border-color: var(--darkgray) transparent var(--darkgray) transparent;
	/* border-color: var(--error) transparent var(--error) transparent; */
	animation: ldsDualRing 1.2s linear infinite reverse;
}

.loadingSpinner__overlay {
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: var(--backdrop);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

@keyframes ldsDualRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
