.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40svh;
	width: 100vw;
}

.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	height: 100%;
	width: 100%;
	font-size: 1.2rem;
}

.logout {
	width: 100%;
}

.logoutForm {
	display: flex;
	flex-direction: column;
	font-weight: bold;
}

@media (max-width: 768px) {
	form {
		width: 100%;
	}

	.container {
		height: 70svh;
	}

	.formContainer {
		width: 80%;
	}
}

.imgWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}

.logo {
	width: 150px;
}

@media (max-width: 768px) {
	.logo {
		width: 80px;
	}
}
