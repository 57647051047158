.container {
	width: 50%;
	height: 8%;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 5px 0;
	opacity: 0.9;
	pointer-events: all;
	cursor: pointer;
}

.OK {
	background-color: var(--confirm);
}

.ERROR {
	background-color: var(--abort);
	color: white;
}

.MESSAGE {
	background-color: aqua;
}

@media (max-width: 1080px) {
	.container {
		width: 100%;
		height: fit-content;
	}
}
