.prePrint {
	display: none;
	background-color: red;
}

.main {
	text-align: center;
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	/* justify-content: center; */
	background-color: gainsboro;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 65%;
	height: 100%;
	overflow: hidden;
}

.menu {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: fit-content;
	padding: 0 1.5%;
}

.profile {
	display: flex;
	flex-direction: column;
	border: 1px solid;
	border-top: 0;
	background-color: var(--light);
	padding: 0 1rem;
}

.icons {
	height: 48px;
	cursor: pointer;
}

.icons:hover {
	border-radius: 35%;
	background-color: white;
}

.recap {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 35%;
	height: 100%;
	min-width: 250px;
}

@media (max-width: 1080px) {
	.main {
		display: flex;
		flex-direction: column;
		overflow: auto;
		height: auto;
		min-height: 100vh;
		width: auto;
		/* padding: 5% 0; */
		padding-bottom: 10%;
	}

	.content {
		height: fit-content;
		overflow: visible;
		width: 100%;
		margin-bottom: 10%;
	}

	.icons {
		height: 32px;
	}

	.recap {
		height: fit-content;
		width: 100%;
	}
}

/* 
@media (max-width: 768px) {} 
*/
