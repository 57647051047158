.base {
	padding: 0.5rem 1.5rem;
	margin: 0.5rem 0.5rem;
	border-radius: 10px;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
	box-shadow: 1px 1px 4px var(--bgColor);
	border: #000 2px solid;
	min-width: fit-content;
}

.confirm {
	background-color: var(--ButtonStdBgColor);
	color: var(--ButtonStdTxtColor);
	border: var(--ButtonStdTxtColor) 2px solid;
}
.confirm:hover,
.confirm:active,
.confirm:focus {
	background-color: var(--ButtonStdTxtColor);
	color: var(--ButtonStdBgColor);
	border: var(--ButtonStdBgColor) 2px solid;
}

.reverse {
	background-color: var(--ButtonStdTxtColor);
	color: var(--ButtonStdBgColor);
	border: var(--ButtonStdBgColor) 2px solid;
}
.reverse:hover,
.reverse:focus,
.reverse:active {
	background-color: var(--ButtonStdBgColor);
	color: var(--ButtonStdTxtColor);
	border: var(--ButtonStdTxtColor) 2px solid;
}
.reverseDanger {
	background-color: var(--ButtonStdTxtColor);
	color: var(--ButtonStdBgColor);
	border: var(--ButtonStdBgColor) 2px solid;
}
.reverseDanger:hover,
.reverseDanger:focus,
.reverseDanger:active {
	background-color: var(--error);
	color: var(--light);
	border: var(--light) 2px solid;
	font-weight: bold;
}

.abort {
	background-color: var(--error);
	color: var(--light);
	border: var(--dark) 2px solid;
	font-weight: bold;
}

.abort:hover,
.abort:focus,
.abort:active {
	background-color: var(--light);
	color: var(--error);
	border: var(--error) 2px solid;
}

.base:disabled,
.base:disabled,
.base:hover:disabled,
.base:focus:disabled,
.base:active:disabled {
	background-color: var(--lightgray);
	color: var(--darkgray);
	border: var(--darkgray) 2px solid;
}

.small {
	font-size: 0.6rem;
}
.big {
	font-size: 1.5rem;
}
